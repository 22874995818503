<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card card-body printableArea" v-else>
      <h3>
        <b>Detail</b>
        <span class="pull-right">
          <button
            type="button"
            @click="goBack()"
            class="btn btn-inverse"
            title="Kembali"
          >
            Kembali
          </button>
        </span>
      </h3>
      <hr />

      <div class="row">
        <div class="col-md-12 justify-content-md-center">
          <gtTabs>
            <gtTab title="Detail Surat">
              <div class="col-md-6 pull-left">
                <small class="text-muted">Kode</small>
                <h6>
                  {{ state.detail.kode === "" ? "-" : state.detail.kode }}
                </h6>
                <small class="text-muxed">Indeks</small>
                <h6>
                  {{ state.detail.name === "" ? "-" : state.detail.name }}
                </h6>
              </div>
            </gtTab>
          </gtTabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { RotateSquare5 } from "vue-loading-spinner";
import ImageItem from "@/components/img/imgItem";
import { gtTab, gtTabs } from "@/components/gtTabs";
export default {
  components: {
    RotateSquare5,
    ImageItem,
    gtTab,
    gtTabs,
  },

  data() {
    return {
      headers: {
        nama_file: {
          label: "Nama File",
          sortable: false,
        },
        nama_pengunggah: {
          label: "Nama Pengunggah",
          sortable: false,
        },
        tanggal_upload: {
          label: "Tanggal Upload",
          sortable: false,
        },
      },
    };
  },

  computed: {
    base() {
      return this.baseUrl;
    },
    state() {
      return this.$store.state.kodeSurat;
    },
    tanggalditerima() {
      return this.formatDate(this.state.detail.tanggal_diterima);
    },
    tanggaldokumen() {
      return this.formatDate(this.state.detail.tanggal_dokumen);
    },
    submitted() {
      return this.formatDate(this.state.detail.created_at);
    },
    modified() {
      return this.formatDate(this.state.detail.updated_at);
    },
  },
  mounted() {
    this.detailLoad();
  },
  methods: {
    detailLoad() {
      const state = {
        loaded: false,
      };
      this.$store.commit("kodeSurat/STATE", state);
      this.$store.dispatch("kodeSurat/getKodeSuratById", this.$route.params);
    },
    goBack() {
      this.$store.dispatch("kodeSurat/onCancel");
    },
  },
};
</script>
